export const initialState: {} = {
  app: {
    publish: {
      formMethod: "CREATE",
      scheduleToEdit: {},
      scheduleToDelete: {}
    },
    themeFiles: {},
    theme: {
      isEdit: false,
      allSchedules: [],
      globalName: "",
      globalNote: "",
      scheduleToEdit: {},
      schedules: {},
      selectedSection: {},
      currentSection: {
        settings: {}
      },
      changedSections: {},
      changedBlocks: {},
      selectedSectionId: "",
      allThemes: [],
      themeFiles: {},
      selectedTemplateKey: "templates/index.json",
      selectedTheme: "",
      currentTheme: {}
    },
    loading: {
      sectionSettingsLoading: true,
      saving: false
    },
    allThemes: []
  },
  merchant: {
    shop: {},
    media: []
  }
};
